@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');
@import './mixins';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: #FDFFFD url('./assets//bg-img.png') no-repeat top center;
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  padding-left: 80px;
  padding-right: 80px;
  @include mobile {
    padding-left: 12px;
    padding-right: 12px;
  }
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.section {
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #031B07;
  opacity: 0.6;
  padding-left: 24px;
  border-left: 2px solid #FEC000;
  margin-bottom: 32px;
}

.center {
  text-align: center;
}

.auto {
  margin: 0 auto;
}
