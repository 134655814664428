@import '../../mixins';

header {
  .header {
    background: linear-gradient(229.37deg, #4EC81C -52.47%, #30C42A -7.96%, #0ABF3C 66.22%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 80px;
    @include mobile {
      padding: 10px 20px;
    }
    .logo {
      width: 126px;
    }
    .nav {
      display: flex;
      @include mobile {
        display: none;
      }
      &__item {
        margin-left: 64px;
        color: #ffffff;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        &:first-child {
          margin-left: 0;
        }
        &-button {
          text-decoration: none;
          color: #ffffff;
          padding: 10px 24px;
          border: 1px solid #FFFFFF;
          border-radius: 30px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
