@import '../../mixins';

footer {
  background-color: #000000;
  .footer {
    padding: 24px 0;
    border-bottom: 1px solid #FFFFFF;
    text-align: center;
  }
  .sections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    @include mobile {
      display: block;
      padding: 20px 0;
    }
    & > div {
      flex: 1;
    }
    &__storeicons {
      display: flex;
      max-width: 350px;
      @include mobile {
        display: block;
      }
      .storeicon {
        margin: 0 6px;
        @include mobile {
          display: block;
          text-align: center;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__nav {
      @include mobile {
        margin: 30px 0;
      }
      .nav {
        display: flex;
        justify-content: center;
        &.sublinks {
          margin-top: 32px;
        }
        &__link {
          margin-left: 12px;
          margin-right: 12px;
          a {
            text-decoration: none;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
          }
          &.sublink {
            a {
              text-decoration: none;
              font-weight: 500;
              font-size: 12px;
              line-height: 19px;
              color: rgba(255,255,255,.7);
            }
          }
          &:first-child { margin-left: 0; }
          &:last-child { margin-right: 0; }
        }
      }
    }
    &__subscribe {
      max-width: 350px;
      background: #131313;
      border-radius: 10px;
      padding: 14px;
      @include mobile {
        margin: 30px 0;
      }
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .form {
        display: flex;
        border-radius: 15px;
        margin-bottom: 19px;
      }
      input {
        flex: 1;
        outline: none;
        background: #1E1E1E;
        border-radius: 15px 0 0 15px;
        border: none;
        padding: 12px 10px;
        color: #ffffff;
        &:focus {
          border: none;
        }
        &.has-error {
          color: red;
        }
      }
      button {
        cursor: pointer;
        background-color: #000000;
        color: #ffffff;
        border: none;
        border-radius: 0 15px 15px 0;
        padding: 12px 22px;
      }
    }
  }
  .copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgba(255,255,255,.8);
    padding: 37px 0;
    @include mobile {
      text-align: center;
    }
  }
}
