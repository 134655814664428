@import '../../mixins';

.app {
  text-align: center;
  margin-bottom: 100px;
  &__bg {
    background: url('../../assets/header-bg.svg') no-repeat top center;
  }
  &__phones {
    height: 584px;
    background: url('../../assets/header-iphones.png') no-repeat center center;
    position: relative;
    @include mobile {
      height: 380px;
      background-size: cover;
    }
  }
  &__about {
    max-width: 800px;
    margin: 0 auto;
  }
  &__title {
    color: #24C330;
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    margin-bottom: 16px;
    @include mobile {
      font-size: 28px;
    }
  }
  &__description {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #08031D;
    opacity: 0.8;
    margin-bottom: 32px;
    @include mobile {
      font-size: 18px;
    }
  }
  &__button {
    background: #24C330;
    border-radius: 30px;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 24px;
    &:hover{
      cursor: pointer;
    }
  }
}

.fn {
  &__title {
    display: flex;
    align-items: center;
    color: #24C330;
    font-weight: 600;
    font-size: 42px;
    margin-bottom: 44px;
    @include mobile {
      font-size: 20px;
    }
    svg {
      margin-right: 18px;
      @include mobile {
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
    }
    b {
      color: #F8D74F;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    @include mobile {
      display: block;
    }
    &-item {
      cursor: pointer;
      margin-left: 56px;
      color: rgba(8, 3, 29, .5);
      border-bottom: 3px solid transparent;
      padding-bottom: 8px;
      @include mobile {
        margin-left: 0;
        margin-bottom: 10px;
      }
      &:first-child {
        margin-left: 0;
      }
      &.active {
        font-weight: bolder;
        border-color: #24C330;
        color: rgba(8, 3, 29, 1);
      }
    }
  }
  &__tab {
    display: flex;
    align-items: center;
    @include mobile {
      display: block;
    }
    &-log {
      &-img {
        background: url('../../assets/temp-logs.png') no-repeat center center;
        height: 697px;
        width: 570px;
        @include mobile {
          display: none;
        }
      }
      &-text {
        flex: 1;
        align-self: flex-start;
        padding: 0 100px;
        @include mobile {
          margin-left: 0;
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 50px;
        }
        .title {
          font-weight: 700;
          font-size: 26px;
          line-height: 45px;
          color: #062B0C;
          margin-bottom: 24px;
        }
        .desc {
          font-weight: 300;
          font-size: 18px;
          line-height: 150%;
          color: #031B07;
          opacity: 0.6;
          padding-left: 24px;
          border-left: 2px solid #FEC000;
          margin-bottom: 32px;
        }
        .button {
          background: #24C330;
          border-radius: 30px;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          text-decoration: none;
          padding: 10px 24px;
        }
      }
    }
  }
}

.design {
  background: url('../../assets/design-bg.png') no-repeat top center;
  background-size: cover;
  padding: 90px 66px;
  @include mobile {
    padding: 60px 20px;
  }
  .title {
    font-size: 30px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 64px;
    @include mobile {
      font-size: 20px;
      line-height: 30px;
    }
  }
  &__container {
    display: flex;
    @include mobile {
      display: block;
    }
    &-item {
      flex: 1;
      padding-left: 24px;
      border-left: 2px solid #FEC000;
      margin: 0 16px;
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.85;
      @include mobile {
        margin-bottom: 30px;
      }
    }
  }
}

.about {
  padding-top: 115px;
  padding-bottom: 90px;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    svg {
      margin-right: 20px;
    }
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 51px;
      color: #24C330;
      @include mobile {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
  &__text {
    display: flex;
    margin-bottom: 90px;
    @include mobile {
      display: block;
      margin-bottom: 30px;
    }
    &-desc {
      flex: 1;
      .text {
        font-weight: 400;
        font-size: 18px;
        color: rgb(3, 27, 7, .6);
      }
    }
    &-img {
      flex: 1;
      background: url('../../assets/about.png') no-repeat top center;
      background-size: contain;
      @include mobile {
        display: none;
      }
    }
  }
}

.phones {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  @include mobile {
    margin-bottom: 50px;
  }
}

.pricing {
  margin-bottom: 122px;
  @include mobile {
    margin-bottom: 30px;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    justify-content: center;
    @include mobile {
      display: block;
    }
    svg {
      margin-right: 20px;
    }
    .title {
      font-weight: 600;
      font-size: 36px;
      line-height: 51px;
      color: #24C330;
    }
  }
  &__desc {
    text-align: center;
    font-size: 18px;
    color: rgba(8, 3, 29, 0.6);
    b {
      color: #24C330;
    }
  }
}

.contact {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 110px;
  @include mobile {
    display: block;
    margin-bottom: 30px;
  }
  &__form {
    position: relative;
    flex: 1 1;
    .successMsg {
      color: green;
      padding: 10px 0;
    }
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      svg {
        margin-right: 20px;
      }
      .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 51px;
        color: #24C330;
      }
    }
    label {
      display: block;
      color: #8D8D8D;
    }
    input {
      width: 100%;
      display: block;
      border: none;
      border-bottom: 1px solid #8D8D8D;
      outline: none;
      padding: 12px 6px;
      color: #8D8D8D;
      &:focus {
        border-bottom-color: #24C330;
      }
      &.has-error {
        border-bottom-color: red;
        &:focus {
          border-bottom-color: red;
        }
      }
    }
    .button {
      padding: 15px 48px;
      background: #24C330;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      border: none;
      font-weight: 600;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer;
      @include mobile {
        display: block;
        width: 100%;
      }
    }
    &-form {
      display: flex;
      @include mobile {
       display: block;
      }
      .field-container {
        flex: 1;
        width: 100%;
        margin-bottom: 24px;
        margin-right: 48px;
        @include mobile {
          width: auto;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .field-container {
      width: 100%;
      margin-bottom: 24px;
    }
  }
  &__img {
    flex: 1 1;
    height: 600px;
    background: url('../../../public/contact-us-img.png') no-repeat center right;
    background-size: contain;
    @include mobile {
      display: none;
    }
  }
}
